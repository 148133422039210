import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'PortalFacturacion',
    props: route => ({
      folio: route.query.folio,
      total: route.query.total
    }),
    component: () => import(/* webpackChunkName: "portal" */ '../views/portal/Index'),
  },
  {
    path: '/factura/:id/:token',
    name: 'EstadoFactura',
    component: () => import(/* webpackChunkName: "portal" */ '../views/portal/StatusFactura'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
