import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FlashAlert from "./components/utils/FlashAlert";

import "./styles/app.scss";

Vue.config.productionTip = false

window.axios = require("axios").default;
window.$ = require('jquery');

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL

require('@popperjs/core')
window.bootstrap = require('bootstrap');
require('bootstrap-select');
$.fn.selectpicker.Constructor.BootstrapVersion = '5';

axios.get('/sanctum/csrf-cookie')

Vue.component('flash-alert', FlashAlert);

Vue.prototype.$refreshSelectPicker = () => {
  Vue.nextTick(() => {
    $('.selectpicker').selectpicker('refresh');
  });
};
import VTooltip from 'v-tooltip';

Vue.use(VTooltip);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
